import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationLt from "./translationLt.json";
import translationEn from "./translationEn.json";
import { format } from "date-fns";
import { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-GB";
import lt from "date-fns/locale/lt";

registerLocale("en", en);
registerLocale("lt", lt);

export const resources = {
  lt: {
    translation: translationLt
  },
  en: {
    translation: translationEn
  }
};

export const languages = ["lt", "en"];

const LANGUAGE: string = "lang";

const setCookie = (name: string, value: string, days = 7, path = "/") => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie =
    name +
    "=" +
    encodeURIComponent(value) +
    "; expires=" +
    expires +
    "; path=" +
    path;
  return value;
};

const getCookie = (name: string) => {
  return document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=");
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, "");
};

export const getLanguage = () => {
  const lng = getCookie(LANGUAGE);
  return lng ? lng : setLanguage("lt");
};

export const setLanguage = (lng: string) => {
  if (i18n.language) i18n.changeLanguage(lng);
  setCookie(LANGUAGE, lng);
  return lng;
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: getLanguage(),
    lng: getLanguage(),
    debug: false,

    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value: any, formatString?: string) {
        if (value instanceof Date) {
          return format(value, formatString ? formatString : "yyyy-MM-dd");
        }

        return value;
      }
    },

    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "small"]
    },
    detection: {
      order: ["localStorage", "cookie"],
      caches: ["localStorage", "cookie"],
      cookieMinutes: 160,
      lookupQuerystring: LANGUAGE,
      lookupFromPathIndex: 0
    }
  });

export default i18n;
